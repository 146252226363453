import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../../components/layout/Layout.component";
import Author from "../../../components/author/Author.component";
import * as style from "../Project.style.module.scss";

const InvestProtect = () => {
  return (
    <Layout>
      <StaticImage
        src="./images/markus-spiske-1LLh8k2_YFk-unsplash.jpg"
        as="header"
        className={style.hero}
      />
      <main className={style.post}>
        <article className={style.article}>
          <div className={style.date}>Project</div>
          <h1>InvestProtect</h1>
          <h2>Introduction</h2>
          <p>
            This is one of the sites that I will not have fond memories of. I
            was given the task of creating a website for an agency specializing
            in VIP protection. However, I was assured that I have a lot of time
            and the project can be created slowly. I started to gather
            information about what agencies do and then there was a 180 degree
            change. The website is to be created in two days. Designed, coded
            and uploaded to the server. It was a huge surprise for me. With a
            lot of effort, we managed to meet the deadline, but it was not the
            end. Stona turned into an open project. In the morning I changed a
            given thing to change the same thing in the afternoon. The project
            dragged on for a long time, changes were made on a live site.
            Unfortunately, some changes could not be discussed, so some things
            have too low contrast. Unfortunately, I am not 100% able to sign
            this project.
          </p>
          <h2>Sketch</h2>
          <StaticImage src="./images/investprotect.png" as="div" />
          <h2>Result</h2>
          <StaticImage src="./images/screenshot_investprotect.png" as="div" />
        </article>
        <aside>
          <Author />
        </aside>
      </main>
    </Layout>
  );
};
export default InvestProtect;

export function Head() {
  return <title>InvestProtect</title>;
}
